.config-layout {
  overflow: hidden;
  .item-menu-left {
    transition: all 0.3s ease-in;
    &.active,
    &:hover {
      background-color: #f4e0e3;
      box-shadow: inset -2px 0px 0px #03A89C;
      border-right: 1px solid #03A89C;
      span,
      svg {
        color: #03A89C !important;
      }
    }

    &.cyan {
      &.active,
      &:hover {
        background-color: #e0f7f2;
        box-shadow: inset -2px 0px 0px #03a89c;
        border-right: 1px solid #03a89c;
        span,
        svg {
          color: #03a89c !important;
        }
      }
    }
  }
}
