@import "~antd/dist/antd.css";
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  margin: 0;
  height: 100%;
  width: 100%;
  position: relative;
  font-family: "Roboto", sans-serif;
  color: rgba(0, 0, 0, 0.85);

  #root {
    height: 100%;
    width: 100%;
  }

  p,
  hr,
  ul,
  section {
    margin: 0;
    padding: 0;
  }
}

.ant-modal-mask {
  background: #262626 !important;
  opacity: 0.8 !important;
}

p {
  margin-bottom: 0;
}

.text-primary {
  @apply text-03A89C;
}

.layout-login {
}

.Crop-Controls {
  margin-bottom: 10px;
}
.Crop-Controls > * {
  margin-bottom: 3px;
}

img {
  max-width: 100%;
}
