.ProgressLoading {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: transparentize($color: #262626, $amount: 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999;

  .content-loading {
    width: 216px;
    height: 140px;
    background: #ffffff;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    .ant-spin-dot-item {
      // background-color: #03A89C;
    }
  }
}
